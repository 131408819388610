import * as React from 'react'
import { useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import ProgressBar from 'react-bootstrap/ProgressBar'

import Dropdown from 'react-bootstrap/Dropdown'

// COMPONENTS
import Layout from 'layouts/main'

import '../styles/components.css'
import Api from '../helpers/api'
import { config } from '../config'
import ProfilePic from '../images/profile_placeholder.png'
import axios from 'axios'
import Loading from '../components/shared/loading'

const StudentsPage = () => {

  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [teams, setTeams] = useState([])
  const [programs, setPrograms] = useState([])
  const [studentImages, setStudentImages] = useState({  });
  const [accessDenied, setAccessDenied] = useState(false);


  useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      let user = (await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)).data
      if(user.role === 'ROLE_STUDENT'){
        setAccessDenied(true);
      }
    } catch (e) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '/?showLogin=true&next=/students'
    }



    Api.getInstance().getStudents().then(response => {
      setStudents(response)
    }).catch(e => {

    }).finally(() => {
      setLoading(false)
    })
    Api.getInstance().getStudentImages().then(response => {
      setStudentImages(response)
    }).catch(e => {

    }).finally(() => {

    })
  }, [])

  const shortNames = {
    "Product Management":"PM",
    "Product Design":"PD",
    "B2B Sales":"B2B",
    "Full-Stack":"FS",
    "Back-end":"BE",
    "Digital Marketing":"DM",
  }

  useEffect(() => {
    let t = students.map(({ teamName }) => teamName)
    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }
    setTeams(t.filter(unique))

    let r = students.map(({ program }) => program)
    setPrograms(r.filter(unique))
  }, [students])


  return <Layout title='Students'>
    <Container className='pb-5'>
      <link
        href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
        rel='stylesheet'
        integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
        crossOrigin='anonymous'
      ></link>

      <h2 className='mt-5 pt-2 fw-medium'>All Students Performance</h2>
      {/*<p className='fs-5 mb-md-5 mb-0'>
        Consectetur adipiscing elit, that interests you
      </p>*/}

      {loading &&
      <div>
        <Loading/>
      </div>
      }
      {accessDenied &&
        <div>You don't have permission to access this page.</div>
      }
      {!loading && !accessDenied &&
        <Table className='table-students mt-5 mb-5 font-size-16' responsive>
        <thead>
        <tr>
          <th className='text-uppercase d-none d-md-table-cell'>Team Member</th>
          <th className='d-block d-md-table-cell'>
            <Dropdown>
              <Dropdown.Toggle id='dropdown-basic'
                               className='border-0 text-start bg-transparent text-dark p-0 fw-bold text-uppercase'>
                Team Name
              </Dropdown.Toggle>
              <Dropdown.Menu>

                <Dropdown.Item onClick={() => {
                  setSelectedTeam(null)
                }}>All</Dropdown.Item>
                {teams.map(team => {
                  return <Dropdown.Item onClick={() => {
                    setSelectedTeam(team)
                  }}>{team}</Dropdown.Item>
                })}
              </Dropdown.Menu>
            </Dropdown>
          </th>
          <th className='d-block d-md-table-cell'>
            <Dropdown>
              <Dropdown.Toggle id='dropdown-basic'
                               className='border-0 text-start bg-transparent text-dark p-0 fw-bold text-uppercase'>
                Role
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => {
                  setSelectedProgram(null)
                }}>All</Dropdown.Item>
                {programs.map(team => {
                  return <Dropdown.Item onClick={() => {
                    setSelectedProgram(team)
                  }}>{shortNames[team]}</Dropdown.Item>
                })}
              </Dropdown.Menu>
            </Dropdown>
          </th>
          <th className='text-uppercase d-none d-md-table-cell'>Project name</th>
          <th className='text-uppercase d-none d-md-table-cell'>Progress</th>
          {/*<th className='text-uppercase d-none d-md-table-cell text-end'>Efficiency</th>*/}
        </tr>
        </thead>
        <tbody className='align-middle border-top-0 last-border-0'>
        {students.filter((student) => {
          return !selectedTeam || student.teamName === selectedTeam
        }).filter((student) => {
          return !selectedProgram || student.program === selectedProgram
        }).map((student, i) => (
          <tr key={i}>
            <td>

              <img
                src={studentImages[student.email] ? `${config.CDN_BASE_URL}/${studentImages[student.email]}`:ProfilePic}
                className='ms-1 me-3 rounded-circle float-start float-md-none'
                style={{width: 56, height: 56}}
                alt=''
              />
              <a href={`/student?id=${student.id}`} className='text-dark d-block d-md-inline'>
                {student.fullName}
              </a>
              <div className='d-inline-flex align-items-center d-md-none mb-3'>
                <small className='my-0 me-2'>Progress:</small>
                <strong className='fw-medium'>{student.progress}%</strong>
              </div>
            </td>
            <td>
              <a href={`/team?id=${student.teamId}`} className='text-dark'>
                {student.teamName}
              </a>
            </td>
            <td>

                {shortNames[student.program]}

            </td>
            <td>
              <a href={`/project?id=${student.teamId}`} className='text-dark'>
                {student.projectName}
              </a>
            </td>
            <td className='pr-lg-1 small'>
              <ProgressBar
                variant='warning'
                now={student.progress}
                className='mb-0 me-2'
              />
              <span className='align-middle'>{student.progress}%</span>
            </td>
            {/*<td className='text-lg-end'>76/100</td>*/}
          </tr>
        ))}
        </tbody>
      </Table>
      }
    </Container>
  </Layout>
}

export default StudentsPage
